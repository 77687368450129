import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "./layout"
import SEO from "./seo"
// import Gallery from "./gallery"
// import Skus from './skus'
// import Add from './add'


export default (props) => {

  const posts = useStaticQuery(graphql`
    query Pages {
      allContentfulPage {
        edges {
          node {
            id
            url
            title
            content {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    } 
  `)
  const currentPage = posts.allContentfulPage.edges.filter(({ node: page }) => { return page.id === props.pageContext.page.id })[0].node
  // console.log(currentPage)
  currentPage['type'] = 'page'
  return (
    <Layout>
      <SEO schemaData={currentPage} title={currentPage.title} description={currentPage.intro} />
      <main className="mx-auto max-w-3xl text-brand-500 text-lg px-4">
        <h1 className="text-4xl font-bold mb-2 leading-tight">{currentPage.title}</h1>
        <div className="flex bg-white mx-auto container pt-10">
          <div className="markdown-page" dangerouslySetInnerHTML={{ __html: currentPage.content.childMarkdownRemark.html }} />
        </div>
      </main>
    </Layout>
  )
}

